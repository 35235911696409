$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mixins/common';

.primary {
  @include interactiveUnderline;
}

.secondary {
  @include genericLink;

  color: var(--b-color-text-dark);

  &:visited {
    color: var(--b-color-text-dark);
  }
}

.inlineLink {
  @include genericLink;
  color: var(--b-color-button-secondary-enabled);
}
