@import 'src/ui/styles/tools';

:root {
  --b-border-radius-50-percent: 50%;
  --b-border-radius-1: #{toRem(1)};
  --b-border-radius-2: #{toRem(2)};
  --b-border-radius-4: #{toRem(4)};
  --b-border-radius-6: #{toRem(6)};
  --b-border-radius-8: #{toRem(8)};
  --b-border-radius-10: #{toRem(10)};
  --b-border-radius-12: #{toRem(12)};
  --b-border-radius-16: #{toRem(16)};
  --b-border-radius-24: #{toRem(24)};
  --b-border-radius-28: #{toRem(28)};
  --b-border-radius-50: #{toRem(50)};
  --b-border-radius-100: #{toRem(100)};
}
