$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';.label {
  margin-bottom: var(--b-space-m);
}

.options {
  display: flex;
  gap: var(--b-space-l);
}

.optionError:not(:checked) {
  color: var(--b-color-support-error);
}
