$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';

.divider__horizontal {
  align-self: stretch;

  width: 100%;
  height: #{toRem(1)};

  background-color: var(--b-color-fill-neutral-02);
}

.divider__vertical {
  align-self: stretch;

  width: #{toRem(1)};
  height: 100%;

  background-color: var(--b-color-fill-neutral-02);
}
