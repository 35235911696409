$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mixins/common';

.primary {
  @include interactiveUnderline;
}

.secondary {
  text-decoration-color: var(--b-color-secondary-link);
  color: var(--b-color-secondary-link);

  &:hover {
    color: var(--b-color-secondary-link-hover);
  }

  &:active {
    color: var(--b-color-secondary-link-pressed);
  }
}

.simple {
  text-decoration: unset;
}

.text {
  @media #{$fromLaptop} {
    overflow: hidden;

    white-space: nowrap;
  }
}
