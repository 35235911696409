@import 'src/ui/styles/tools/mediaQueries';

$directions: (
  't': 'top',
  'b': 'bottom',
  'l': 'left',
  'r': 'right',
);

$sizes: (
  'zero': 0,
  'xxs': var(--b-space-xxs),
  'xs': var(--b-space-xs),
  's': var(--b-space-s),
  'm': var(--b-space-m),
  'l': var(--b-space-l),
  'xl': var(--b-space-xl),
  '2xl': var(--b-space-2xl),
  '3xl': var(--b-space-3xl),
  '4xl': var(--b-space-4xl),
  '5xl': var(--b-space-5xl),
  'auto': auto,
);

$devices: (
  'mobile': $onlyMobile,
  'tablet': $onlyTablet,
  'laptop': $onlyLaptop,
  'desktop': $onlyDesktop,
);

@each $nameSize, $size in $sizes {
  @each $nameDir, $dir in $directions {
    @each $device, $deviceMediaQuery in $devices {
      .m#{$nameDir}-#{$device}-#{$nameSize} {
        @media #{$deviceMediaQuery} {
          @if $dir == 'left' {
            [dir='rtl'] & {
              margin-right: $size !important;
            }
            html:not([dir='rtl']) & {
              margin-left: $size !important;
            }
          }

          @if $dir == 'right' {
            [dir='rtl'] & {
              margin-left: $size !important;
            }
            html:not([dir='rtl']) & {
              margin-right: $size !important;
            }
          }

          @if $dir == 'top' {
            margin-top: $size !important;
          }

          @if $dir == 'bottom' {
            margin-bottom: $size !important;
          }
        }
      }
    }
  }
}

@each $nameSize, $size in $sizes {
  @each $nameDir, $dir in $directions {
    .m#{$nameDir}-#{$nameSize} {
      @if $dir == 'left' {
        [dir='rtl'] & {
          margin-right: $size;
        }
        html:not([dir='rtl']) & {
          margin-left: $size;
        }
      }

      @if $dir == 'right' {
        [dir='rtl'] & {
          margin-left: $size;
        }
        html:not([dir='rtl']) & {
          margin-right: $size;
        }
      }

      @if $dir == 'top' {
        margin-top: $size;
      }

      @if $dir == 'bottom' {
        margin-bottom: $size;
      }
    }
  }
}
