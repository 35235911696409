$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/functions';

.carouselContent {
  height: 100%;
}

.carouselContainer {
  height: 100%;

  @media #{$fromLaptop} {
    cursor: pointer;
  }
}

.carousel {
  --swiper-pagination-bullet-horizontal-gap: var(--b-space-xxs);
  --swiper-pagination-bullet-vertical-gap: var(--b-space-m);

  --swiper-pagination-bullet-width: #{toRem(6)};
  --swiper-pagination-bullet-height: #{toRem(6)};

  --swiper-theme-color: var(--b-color-icon-light);
  --swiper-pagination-bullet-inactive-color: var(--b-color-icon-light);
  --swiper-pagination-bullet-inactive-opacity: 0.5;

  height: 100%;

  @media #{$fromLaptop} {
    margin: unset;
    border-radius: toRem(16);
  }

  :global(.swiper-wrapper) {
    --laptop-max-width: #{toRem(370)};

    margin: 0;
    width: 100%;
    max-width: var(--laptop-max-width);
    padding: 0;
  }

  :global(.swiper-pagination-bullet-active) {
    border-radius: toRem(100);
    width: toRem(20);
    height: toRem(6);
  }

  &_fullScreen {
    @media #{$onlyMobileAndTablet} and (orientation: landscape) {
      margin: unset;
      max-width: #{toRem(651)};
    }
  }
}

.securityZone {
  --security-zone-width: var(--b-space-5xl);
  position: absolute;
  top: calc(50% - var(--padding) * 2);
  left: calc(var(--security-zone-width) * -1);

  width: var(--security-zone-width);
  height: 100%;

  &_right {
    top: 0;
    right: calc(var(--security-zone-width) * -1);
    left: unset;
  }
}

.img {
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.visuallyHidden:not(:focus):not(:active) {
  position: absolute;

  overflow: hidden;

  width: toRem(1);
  height: toRem(1);

  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
}

.carouselAlt {
  visibility: hidden;
}

.slide {
  display: flex;

  list-style: none;
}

.roundedCorners {
  border-radius: #{toRem(16)};
}

.fullScreenButton {
  position: absolute;
  z-index: var(--b-z-index-1);
  top: var(--b-space-m);
  right: var(--b-space-m);

  [dir='rtl'] & {
    right: unset;
    left: var(--b-space-m);
  }
}
