$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';.light {
  border-radius: var(--b-border-radius-8);
  padding: var(--b-space-xxs) var(--b-space-xs);

  white-space: nowrap;

  background-color: var(--b-color-support-success-light);
  color: var(--b-color-icon-dark);
}

.bold {
  border-radius: var(--b-border-radius-8);
  width: fit-content;
  padding: var(--b-space-xxs) var(--b-space-xs);

  white-space: pre-wrap;

  background-color: var(--b-color-support-success);
  color: var(--b-color-text-light);
}
