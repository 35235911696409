$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';
@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/functions';

.notificationWrapper {
  position: fixed;
  z-index: var(--b-z-index-5);
  bottom: var(--b-space-m);

  // estrategia de centrado horizontal
  transform: translateX(-50%);

  margin-left: 50%;

  // Restamos al tamaño del elemento los márgenes laterales que queremos que
  // tenga cuando su tamaño sea menor al max-width
  width: calc(100% - var(--b-space-m) - var(--b-space-m));
  max-width: calc(#{toRem(424)});

  animation: slideInBottom 0.5s ease-in-out;

  @media #{$fromTablet} {
    bottom: var(--b-space-3xl);
  }

  [dir='rtl'] & {
    transform: translateX(50%);

    margin-right: 50%;
  }
}

@keyframes slideInBottom {
  from {
    transform: translate(-50%, 100vh);
  }
  to {
    transform: translate(-50%, 0);
  }
}

.notification {
  border: toRem(1) solid var(--b-color-fill-neutral-03);
  border-radius: toRem(8);
  padding: var(--b-space-s) var(--b-space-2xl) var(--b-space-s) var(--b-space-s);

  color: var(--b-color-text-dark);
  background-color: var(--b-color-fill-neutral-01);

  &.success {
    border-color: var(--b-color-support-success-dark);

    background-color: var(--b-color-support-success-light);
  }

  &.info {
    border-color: var(--b-color-support-info-dark);

    background-color: var(--b-color-support-info-light);
  }

  &.warning {
    border-color: var(--b-color-support-warning-dark);

    background-color: var(--b-color-support-warning-light);
  }

  &.error {
    border-color: var(--b-color-support-error-dark);

    background-color: var(--b-color-support-error-light);
  }

  &.darkVariant {
    border-color: var(--b-color-fill-neutral-04);

    color: var(--b-color-text-light);

    background-color: var(--b-color-fill-neutral-04);
  }
}

.exitButton {
  position: absolute;
  right: var(--b-space-s);

  cursor: pointer;
}
