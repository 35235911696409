$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';

.footer {
  display: flex;
  justify-content: flex-end;

  margin-top: var(--b-space-l);
  border-top: toRem(1) solid var(--b-color-divider);
  padding: var(--b-space-l) 0;
}
