$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';
@import 'src/ui/styles/tools/mixins/containers';
@import 'src/ui/styles/tools/mixins/gridLayout';

@function maxContentHeight($total, $bottomSpace) {
  @return calc($total - var(--modal-header-height) - $bottomSpace);
}

.container {
  @include fullWidthContainerLateralPadding;
  position: fixed;
  z-index: var(--b-z-index-5);
  top: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  background-color: var(--b-color-bg-overlay);

  @media #{$onlyMobile} {
    &_isMobileFullscreen {
      padding: unset;
    }
  }
}

.wrapper {
  justify-items: center;

  width: 100%;

  @media #{$fromTablet} {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-column-gap: var(--b-grid-mobile-gutter-gap);
    column-gap: var(--b-grid-mobile-gutter-gap);
  }

  @media #{$fromLaptop} {
    @include gridLayout;
  }

  @media #{$onlyMobile} {
    &_isMobileFullscreen {
      display: unset;

      height: 100%;
    }
  }
}

.modal {
  --modal-header-padding-top: var(--b-space-l);
  --modal-header-padding-bottom: var(--b-space-l);
  --modal-close-icon-size: var(--b-space-l);

  --modal-header-height: calc(
    var(--modal-header-padding-top) + var(--modal-header-padding-bottom) +
      var(--modal-close-icon-size)
  );

  position: fixed;
  bottom: 0;
  left: 0;

  overflow: hidden;

  border-radius: #{toRem(16)} #{toRem(16)} 0 0;
  width: 100%;

  background-color: var(--b-color-bg-white);

  @media #{$fromTablet} {
    position: unset;

    border-radius: #{toRem(16)};
  }

  &_s {
    @media #{$fromTablet} {
      grid-column: 2 / span 6;

      max-width: #{toRem(414)};
    }

    @media #{$fromLaptop} {
      grid-column: 4 / span 6;
    }

    @media #{$onlyDesktop} {
      grid-column: 4 / span 6;
    }
  }

  &_m {
    @media #{$fromTablet} {
      grid-column: 2 / span 6;

      max-width: #{toRem(536)};
    }

    @media #{$fromLaptop} {
      grid-column: 4 / span 6;
    }

    @media #{$onlyDesktop} {
      grid-column: 4 / span 6;
    }
  }

  &_l {
    @media #{$fromTablet} {
      grid-column: 2 / span 6;

      max-width: #{toRem(798)};
    }

    @media #{$fromLaptop} {
      grid-column: 3 / span 8;
    }

    @media #{$onlyDesktop} {
      grid-column: 3 / span 8;
    }
  }

  &_xl {
    @media #{$fromTablet} {
      grid-column: 1 / -1;

      max-width: #{toRem(1090)};
    }

    @media #{$fromLaptop} {
      grid-column: 1 / -1;
    }

    @media #{$onlyDesktop} {
      grid-column: 2 / span 10;
    }
  }

  @media #{$onlyMobile} {
    &_isMobileFullscreen {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr auto;

      border-radius: unset;
      max-width: unset;
      height: 100%;
    }

    &_isMobileCentered {
      position: unset;

      border-radius: #{toRem(16)};
    }
  }
}

.modalTools {
  display: flex;
  justify-content: flex-end;

  height: var(--modal-header-height);
  padding-top: (var(--modal-header-padding-top));
  padding-bottom: (var(--modal-header-padding-bottom));
  padding-left: var(--b-space-l);
  padding-right: var(--b-space-l);
}

.customModalToolsWrapper {
  position: relative;
}

.customModalTools {
  position: absolute;

  inset: 0 0 auto;
}

.exitButton {
  @media #{$fromLaptop} {
    &:hover {
      cursor: pointer;
    }
  }
}
