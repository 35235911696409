$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';.container {
  position: relative;

  display: inline-block;

  width: var(--size);
  height: var(--size);
}

.loader {
  --size-to-border-ratio: 8;
  --border-size: calc(var(--size) / var(--size-to-border-ratio));

  position: absolute;

  display: block;

  border: #{toRem(7)} solid transparent;
  border-radius: 1.75rem;
  border-radius: 50%;
  box-sizing: border-box;
  width: var(--size);
  height: var(--size);
  padding: 0.5rem 1rem;

  animation: spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

  background: transparent;
  background-image: linear-gradient(
      90deg,
      var(--b-color-bg-white),
      var(--b-color-bg-white)
    ),
    conic-gradient(var(--color1) 0deg, var(--color2) 270deg, transparent 270deg);
  background-clip: padding-box, border-box;
  background-origin: border-box;

  &:nth-child(1) {
    animation-delay: -0.45s;
  }

  &:nth-child(2) {
    animation-delay: -0.3s;
  }

  &:nth-child(3) {
    animation-delay: -0.15s;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
