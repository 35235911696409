$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';

/**** CLASSES ****/
.mobile-xs-300 {
  @include font-xs-300;
}

.mobile-xs-500 {
  @include font-xs-500;
}

.mobile-xs-700 {
  @include font-xs-700;
}

.mobile-s-300 {
  @include font-s-300;
}

.mobile-s-500 {
  @include font-s-500;
}

.mobile-s-700 {
  @include font-s-700;
}

.mobile-s-link {
  @include font-s-link;
}

.mobile-m-300 {
  @include font-m-300;
}

.mobile-m-500 {
  @include font-m-500;
}

.mobile-m-700 {
  @include font-m-700;
}

.mobile-link {
  @include font-link;
}

.mobile-button {
  @include font-button;
}

.mobile-ml-300 {
  @include font-ml-300;
}

.mobile-ml-500 {
  @include font-ml-500;
}

.mobile-ml-700 {
  @include font-ml-700;
}

.mobile-l-300 {
  @include font-l-300;
}

.mobile-l-500 {
  @include font-l-500;
}

.mobile-l-700 {
  @include font-l-700;
}

.mobile-xl-300 {
  @include font-xl-300;
}

.mobile-xl-500 {
  @include font-xl-500;
}

.mobile-xl-700 {
  @include font-xl-700;
}

.mobile-2xl-500 {
  @include font-2xl-500;
}

.mobile-2xl-700 {
  @include font-2xl-700;
}

.mobile-3xl-700 {
  @include font-3xl-700;
}

.mobile-4xl-500 {
  @include font-4xl-500;
}

@media #{$fromTablet} {
  .tablet-xs-300 {
    @include font-xs-300;
  }

  .tablet-xs-500 {
    @include font-xs-500;
  }

  .tablet-xs-700 {
    @include font-xs-700;
  }

  .tablet-s-300 {
    @include font-s-300;
  }

  .tablet-s-500 {
    @include font-s-500;
  }

  .tablet-s-700 {
    @include font-s-700;
  }

  .mobile-s-link {
    @include font-s-link;
  }

  .tablet-button {
    @include font-button;
  }

  .tablet-m-300 {
    @include font-m-300;
  }

  .tablet-m-500 {
    @include font-m-500;
  }

  .tablet-link {
    @include font-link;
  }

  .tablet-m-700 {
    @include font-m-700;
  }

  .tablet-ml-300 {
    @include font-ml-300;
  }

  .tablet-ml-500 {
    @include font-ml-500;
  }

  .tablet-ml-700 {
    @include font-ml-700;
  }

  .tablet-l-300 {
    @include font-l-300;
  }

  .tablet-l-500 {
    @include font-l-500;
  }

  .tablet-l-700 {
    @include font-l-700;
  }

  .tablet-xl-300 {
    @include font-xl-300;
  }

  .tablet-xl-500 {
    @include font-xl-500;
  }

  .tablet-xl-700 {
    @include font-xl-700;
  }

  .tablet-2xl-500 {
    @include font-2xl-500;
  }

  .tablet-2xl-700 {
    @include font-2xl-700;
  }

  .tablet-3xl-700 {
    @include font-3xl-700;
  }

  .tablet-4xl-500 {
    @include font-4xl-500;
  }
}

@media #{$fromLaptop} {
  .laptop-xs-300 {
    @include font-xs-300;
  }

  .laptop-xs-500 {
    @include font-xs-500;
  }

  .laptop-xs-700 {
    @include font-xs-700;
  }

  .laptop-s-300 {
    @include font-s-300;
  }

  .laptop-s-500 {
    @include font-s-500;
  }

  .laptop-s-700 {
    @include font-s-700;
  }
  .laptop-s-link {
    @include font-s-link;
  }

  .laptop-button {
    @include font-button;
  }

  .laptop-m-300 {
    @include font-m-300;
  }

  .laptop-m-500 {
    @include font-m-500;
  }

  .laptop-m-700 {
    @include font-m-700;
  }

  .laptop-link {
    @include font-link;
  }

  .laptop-ml-300 {
    @include font-ml-300;
  }

  .laptop-ml-500 {
    @include font-ml-500;
  }

  .laptop-ml-700 {
    @include font-ml-700;
  }

  .laptop-l-300 {
    @include font-l-300;
  }

  .laptop-l-500 {
    @include font-l-500;
  }

  .laptop-l-700 {
    @include font-l-700;
  }

  .laptop-xl-300 {
    @include font-xl-300;
  }

  .laptop-xl-500 {
    @include font-xl-500;
  }

  .laptop-xl-700 {
    @include font-xl-700;
  }

  .laptop-2xl-500 {
    @include font-2xl-500;
  }

  .laptop-2xl-700 {
    @include font-2xl-700;
  }

  .laptop-3xl-700 {
    @include font-3xl-700;
  }

  .laptop-4xl-500 {
    @include font-4xl-500;
  }
}

@media #{$onlyDesktop} {
  .desktop-xs-300 {
    @include font-xs-300;
  }

  .desktop-xs-500 {
    @include font-xs-500;
  }

  .desktop-xs-700 {
    @include font-xs-700;
  }

  .desktop-s-300 {
    @include font-s-300;
  }

  .desktop-s-500 {
    @include font-s-500;
  }

  .desktop-s-700 {
    @include font-s-700;
  }

  .desktop-s-link {
    @include font-s-link;
  }

  .desktop-button {
    @include font-button;
  }

  .desktop-m-300 {
    @include font-m-300;
  }

  .desktop-m-500 {
    @include font-m-500;
  }

  .desktop-m-700 {
    @include font-m-700;
  }

  .desktop-link {
    @include font-link;
  }

  .desktop-ml-300 {
    @include font-ml-300;
  }

  .desktop-ml-500 {
    @include font-ml-500;
  }

  .desktop-ml-700 {
    @include font-ml-700;
  }

  .desktop-l-300 {
    @include font-l-300;
  }

  .desktop-l-500 {
    @include font-l-500;
  }

  .desktop-l-700 {
    @include font-l-700;
  }

  .desktop-xl-300 {
    @include font-xl-300;
  }

  .desktop-xl-500 {
    @include font-xl-500;
  }

  .desktop-xl-700 {
    @include font-xl-700;
  }

  .desktop-2xl-500 {
    @include font-2xl-500;
  }

  .desktop-2xl-700 {
    @include font-2xl-700;
  }

  .desktop-3xl-700 {
    @include font-3xl-700;
  }

  .desktop-4xl-500 {
    @include font-4xl-500;
  }
}

/** COLORS **/
.color-light {
  color: var(--b-color-text-light);
}

.color-mid {
  color: var(--b-color-text-mid);
}

.color-primary {
  color: var(--b-color-text-primary);
}

.color-button-secondary {
  color: var(--b-color-button-secondary-enabled);
}

.color-dark {
  color: var(--b-color-text-dark);
}

.color-ocean-100 {
  color: var(--b-color-fill-ocean-100);
}

.color-fill-01 {
  color: var(--b-color-fill-neutral-03);
}

.color-disabled {
  color: var(--b-color-text-disabled);
  -webkit-text-fill-color: var(--b-color-text-disabled);
}

.color-support-success {
  color: var(--b-color-support-success);
}

.color-support-success-dark {
  color: var(--b-color-support-success-dark);
}

.color-support-error {
  color: var(--b-color-support-error);
}

.color-support-error-dark {
  color: var(--b-color-support-error-dark);
}

.color-support-info-dark {
  color: var(--b-color-support-info-dark);
}

.color-myBarcelo-dark {
  color: var(--b-color-text-myBarcelo-dark);
}

.toUppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.centered {
  text-align: center;
}

.mybarcelo {
  font-family:
    var(--b-mybarcelo-font-family),
    var(--b-font-family),
    Roboto,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
}

/**** CLASSES MYBARCELO ****/
.mybarcelo-mobile-xl-700 {
  @include mybarcelo-font-xl-700;
}

.mybarcelo-mobile-xl-500 {
  @include mybarcelo-font-xl-500;
}

.mybarcelo-mobile-2xl-500 {
  @include mybarcelo-font-2xl-500;
}

.mybarcelo-mobile-2xl-700 {
  @include mybarcelo-font-2xl-700;
}

.mybarcelo-mobile-3xl-500 {
  @include mybarcelo-font-3xl-500;
}

.mybarcelo-mobile-3xl-700 {
  @include mybarcelo-font-3xl-700;
}

.mybarcelo-mobile-4xl-700 {
  @include mybarcelo-font-4xl-700;
}

.mybarcelo-mobile-5xl-500 {
  @include mybarcelo-font-5xl-500;
}

.mybarcelo-mobile-6xl-500 {
  @include mybarcelo-font-6xl-500;
}

@media #{$fromTablet} {
  .mybarcelo-tablet-xl-500 {
    @include mybarcelo-font-xl-500;
  }

  .mybarcelo-tablet-2xl-500 {
    @include mybarcelo-font-2xl-500;
  }

  .mybarcelo-tablet-2xl-700 {
    @include mybarcelo-font-2xl-700;
  }

  .mybarcelo-tablet-3xl-500 {
    @include mybarcelo-font-3xl-500;
  }

  .mybarcelo-tablet-3xl-700 {
    @include mybarcelo-font-3xl-700;
  }

  .mybarcelo-tablet-4xl-700 {
    @include mybarcelo-font-4xl-700;
  }

  .mybarcelo-tablet-5xl-500 {
    @include mybarcelo-font-5xl-500;
  }

  .mybarcelo-tablet-6xl-500 {
    @include mybarcelo-font-6xl-500;
  }
}

@media #{$fromLaptop} {
  .mybarcelo-laptop-xl-500 {
    @include mybarcelo-font-xl-500;
  }

  .mybarcelo-laptop-2xl-500 {
    @include mybarcelo-font-2xl-500;
  }

  .mybarcelo-laptop-2xl-700 {
    @include mybarcelo-font-2xl-700;
  }

  .mybarcelo-laptop-3xl-500 {
    @include mybarcelo-font-3xl-500;
  }

  .mybarcelo-laptop-3xl-700 {
    @include mybarcelo-font-3xl-700;
  }

  .mybarcelo-laptop-4xl-500 {
    @include mybarcelo-font-4xl-700;
  }

  .mybarcelo-laptop-5xl-500 {
    @include mybarcelo-font-5xl-500;
  }

  .mybarcelo-laptop-6xl-500 {
    @include mybarcelo-font-6xl-500;
  }
}

@media #{$onlyDesktop} {
  .mybarcelo-desktop-xl-500 {
    @include mybarcelo-font-xl-500;
  }

  .mybarcelo-desktop-xl-700 {
    @include mybarcelo-font-xl-700;
  }

  .mybarcelo-desktop-2xl-500 {
    @include mybarcelo-font-2xl-500;
  }

  .mybarcelo-desktop-2xl-700 {
    @include mybarcelo-font-2xl-700;
  }

  .mybarcelo-desktop-3xl-500 {
    @include mybarcelo-font-3xl-500;
  }

  .mybarcelo-desktop-3xl-700 {
    @include mybarcelo-font-3xl-700;
  }

  .mybarcelo-desktop-4xl-700 {
    @include mybarcelo-font-4xl-700;
  }

  .mybarcelo-desktop-5xl-500 {
    @include mybarcelo-font-5xl-500;
  }

  .mybarcelo-desktop-6xl-500 {
    @include mybarcelo-font-6xl-500;
  }
}
