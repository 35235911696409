@import 'src/ui/styles/tools/functions';

@function calculateFromGrid($grid-base, $multiplier) {
  $result: $grid-base * $multiplier;

  @return $result;
}

:root {
  $grid-base: 8;

  --b-space-xxs: #{toRem(calculateFromGrid($grid-base, 0.5))}; // 4px
  --b-space-xs: #{toRem(calculateFromGrid($grid-base, 1))}; // 8px
  --b-space-s: #{toRem(calculateFromGrid($grid-base, 1.5))}; // 12px
  --b-space-m: #{toRem(calculateFromGrid($grid-base, 2))}; // 16px
  --b-space-l: #{toRem(calculateFromGrid($grid-base, 3))}; // 24px
  --b-space-xl: #{toRem(calculateFromGrid($grid-base, 4))}; // 32px
  --b-space-2xl: #{toRem(calculateFromGrid($grid-base, 5))}; // 40px
  --b-space-3xl: #{toRem(calculateFromGrid($grid-base, 6))}; // 48px
  --b-space-4xl: #{toRem(calculateFromGrid($grid-base, 8))}; // 64px
  --b-space-5xl: #{toRem(calculateFromGrid($grid-base, 12))}; // 96px
}
