@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mediaQueries';

:root {
  --footer-height: #{toRem(74)};
  --header-height: #{toRem(155)};
  --header-small-height: #{toRem(50)};

  --b-grid-max-container: #{toRem(1312)};

  --b-grid-mobile-lateral-gap: #{toRem(16)};
  --b-grid-tablet-lateral-gap: #{toRem(24)};
  --b-grid-laptop-lateral-gap: #{toRem(32)};
  --b-grid-desktop-lateral-gap: #{toRem(32)};
  /* prettier-ignore */
  --b-grid-full-container-desktop-lateral-gap: calc(
    calc(100vw - var(--b-grid-max-container)) / 2 + var(--b-grid-desktop-lateral-gap)
  );

  --b-grid-mobile-gutter-gap: #{toRem(16)};
  --b-grid-desktop-gutter-gap: #{toRem(24)};

  --b-grid-lateral-gap: var(--b-grid-mobile-lateral-gap);

  @media #{$fromTablet} {
    --b-grid-lateral-gap: var(--b-grid-tablet-lateral-gap);
  }

  @media #{$fromLaptop} {
    --b-grid-lateral-gap: var(--b-grid-laptop-lateral-gap);
  }

  @media #{$onlyDesktop} {
    --b-grid-lateral-gap: var(--b-grid-full-container-desktop-lateral-gap);
  }
}
