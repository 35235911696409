$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/mixins/containers';
@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mixins/common';
@import 'src/ui/styles/tools/mixins/gridLayout';

.overlay {
  position: fixed;
  z-index: var(--b-z-index-5);
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  width: 100%;
  height: 100%;

  background-color: var(--b-color-bg-overlay);
}

.topSection {
  @include contentContainer;
  flex-grow: 1;

  width: 100%;
  max-width: toRem(962);

  @media #{$onlyMobileAndTablet} and (orientation: landscape) {
    max-height: var(--b-space-m);
    min-height: var(--b-space-m);
  }

  @media #{$fromLaptop} {
    padding-left: unset;
    padding-right: unset;
  }
}

.carouselSection {
  position: relative;

  margin: auto var(--b-space-m);
  border-radius: toRem(16);
  width: 100%;
  max-width: toRem(866);
  height: auto;

  @media #{$onlyMobile} and (orientation: landscape) {
    margin: unset;
    max-width: toRem(651);
    height: inherit;
    padding: unset;
  }

  @media #{$fromLaptop} {
    margin: unset;
    min-height: toRem(600);
  }
}

.carouselContent {
  height: 100%;
}

.carouselContainer {
  height: 100%;

  @media #{$fromLaptop} {
    cursor: pointer;
  }
}

.bottomSection {
  flex-grow: 1;

  width: 100%;

  @media #{$onlyMobileAndTablet} and (orientation: landscape) {
    max-height: var(--b-space-m);
    min-height: var(--b-space-m);
  }
}
