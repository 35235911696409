$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';

.container {
  position: fixed;
  z-index: var(--b-z-index-1);
  right: 0;
  //Altura definida para no tapar CTA's
  //El widget mide 86px y debería estar a 200px -> 200+86
  bottom: toRem(271);

  display: flex;
  justify-content: flex-end;

  width: 100%;
  height: 0;
  padding-right: var(--b-space-m);

  [dir = 'rtl'] & {
    padding-left: var(--b-space-m);
    padding-right: unset;
    }

  @media #{$fromLaptop}{
    bottom: toRem(275);

    padding-right: var(--b-space-l);

    [dir='rtl'] & {
      padding-left: var(--b-space-l);
      padding-right: unset;
      }
  }
}

.clickableZone {
  position: relative;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  height: toRem(86);
}

.imageContainer {
  position: relative;
  z-index: var(--b-z-index-1);

  align-self: start;
  overflow: hidden;

  box-shadow: 0 toRem(2) toRem(12) 0 rgba(0, 0, 0, 0.30);
  border: toRem(2) solid var(--b-color-bg-white);
  border-radius: var(--b-border-radius-50-percent);
  width: toRem(80);
  height: toRem(80);

  background-image: url('https://s7g10.scene7.com/is/image/barcelo/widgetCMB?fmt=png8-alpha');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;

  cursor: pointer;

  transition: background-size 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background-size: 110%;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: var(--b-z-index-1);
    top: 0;
    left: 0;

    border-radius: var(--b-border-radius-50-percent);
    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.2);
    transition: opacity 0.3s ease;
  }

  &:hover::before {
    opacity: 0;
  }
}


.iconContainer {
  position: absolute;
  z-index: var(--b-z-index-2);

  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0 toRem(2) toRem(12) 0 rgba(0, 0, 0, 0.30);
  border-radius: var(--b-border-radius-50-percent);
  width: toRem(28);
  height: toRem(28);

  background-image: var(--b-color-fill-ocean-100-gradient);

  cursor: pointer;
}

.messageContainer {
  position: absolute;
  right: toRem(100);
  bottom: toRem(-65);

  display: flex;
  gap: var(--b-space-xs);
  align-items: center;
  opacity: 0;
  transform: translateX(toRem(100));

  box-shadow: 0 toRem(2) toRem(12) 0 rgba(0, 0, 0, 0.30);
  border-top-left-radius: var(--b-border-radius-50);
  border-bottom-left-radius: var(--b-border-radius-50);
  padding: var(--b-space-s) var(--b-space-m);

  text-overflow: ellipsis;
  text-wrap: nowrap;

  background: var(--b-color-bg-white);
  visibility: hidden;
  transition:
    opacity 0.2s ease-out,
    transform 0.5s ease,
    visibility 0.3s ease;

  @media #{$fromLaptop}{
    right: toRem(120);
  }

  [dir = 'rtl'] & {
    right: unset;
    left: toRem(70);

    transform: translateX(toRem(-50));

    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
    border-top-right-radius: var(--b-border-radius-50);
    border-bottom-right-radius: var(--b-border-radius-50);

    }

}

.clickableZone:hover ~ .messageContainer,
.messageContainer:hover {
  opacity: 1;
  transform: translateX(var(--b-space-m));

  @media #{$fromLaptop}{
  transform: translateX(var(--b-space-l));

  }

  visibility: visible;

  [dir ='rtl'] & {
    transform: translateX(100);
  }
}

.textLink {
  cursor: pointer;

  &:hover{
    text-decoration: underline;
    color: var(--b-color-text-primary);
    font-weight: 500;
  }
}
