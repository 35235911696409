$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';

.alertWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
}

.alert {
  border: toRem(1) solid var(--b-color-fill-neutral-03);
  border-radius: toRem(8);
  width: 100%;
  padding: var(--b-space-s);

  @media #{$fromLaptop} {
    padding: var(--b-space-m);
  }
  &.success {
    border-color:var(--b-color-support-success-dark);

    background-color: var(--b-color-support-success-light);
  }

  &.info {
    border-color: var(--b-color-support-info-dark);

    background-color: var(--b-color-support-info-light);
  }

  &.warning {
    border-color: var(--b-color-support-warning-dark);

    background-color: var(--b-color-support-warning-light);
  }

  &.error {
    border-color: var(--b-color-support-error-dark);

    background-color: var(--b-color-support-error-light);
  }

  &.ignored {
    border-color: var(--b-color-fill-neutral-03);

    background-color: var(--b-color-fill-neutral-01);
  }
}
