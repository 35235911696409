$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';

.container {
  position: fixed;
  z-index: var(--b-z-index-4);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: grid;
  place-items: center;

  padding: var(--b-space-m);

  background-color: var(--b-color-bg-white);
}

.content {
  text-align: center;
}

.loaderWrapper {
  margin-bottom: var(--b-space-xl);
}

.mainText {
  max-width: #{toRem(524)};
}

.secondaryText {
  max-width: #{toRem(702)};
}
