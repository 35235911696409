$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';

.label {
  display: flex;
  gap: var(--b-space-s);

  cursor: pointer;
}

.inputRadio[type='radio'] {
  flex-shrink: 0;

  border: toRem(1) solid currentColor;
  border-radius: var(--b-border-radius-50-percent);
  width: var(--b-space-l);
  height: var(--b-space-l);

  appearance: none;
  color: var(--b-color-interactive-primary-active);

  cursor: pointer;

  &:checked {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: currentColor;

    &::before {
      content: '';

      width: var(--b-space-m);
      height: var(--b-space-m);

      background-color: var(--b-color-fill-neutral-00);
      mask: url('/assets/images/Check.svg') center no-repeat;
    }
  }
}
