$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';.modal {
  padding-top: var(--b-space-m);
}

.schedule {
  gap: var(--b-space-m);
  flex-direction: column;
}

.scheduleOptions {
  gap: var(--b-space-l);
}
