.visually-hidden:not(:focus):not(:active) {
  position: absolute;

  overflow: hidden;

  border: 0;
  width: 1px;
  height: 1px;
  padding: 0;

  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
}

.inline-block {
  display: inline-block;
}
