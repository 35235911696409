$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mixins/containers';
@import 'src/ui/styles/tools/mixins/fonts';
@import 'src/ui/styles/tools/mixins/common';

.imageContainer {
  position: relative;

  height: #{toRem(240)};

  @media #{$fromLaptop} {
    height: #{toRem(450)};
  }
}

.image {
  object-fit: cover;
}

.textContainer {
  padding: var(--b-space-l) var(--b-space-m);

  @media #{$fromLaptop} {
    padding: var(--b-space-3xl) var(--b-space-4xl);
  }
}

.divider {
  margin: var(--b-space-m) 0;
}

.buttonWrapper {
  display: flex;
  gap: var(--b-space-m);
  flex-direction: column;

  width: 100%;

  @media #{$fromLaptop} {
    flex-direction: row;

    width: unset;
  }
}
