@import 'src/ui/styles/tools/functions';

@function calculateFromGrid($grid-base, $multiplier) {
  $result: $grid-base * $multiplier;

  @return $result;
}

:root {
  $grid-base: 8;

  --b-icon-3xs: #{toRem(calculateFromGrid($grid-base, 0.5))}; // 4px
  --b-icon-2xs: #{toRem(calculateFromGrid($grid-base, 1))}; // 8px
  --b-icon-xs: #{toRem(calculateFromGrid($grid-base, 1.5))}; // 12px
  --b-icon-s: #{toRem(calculateFromGrid($grid-base, 2))}; // 16px
  --b-icon-m: #{toRem(calculateFromGrid($grid-base, 2.5))}; // 20px
  --b-icon-l: #{toRem(calculateFromGrid($grid-base, 3))}; // 24px
  --b-icon-xl: #{toRem(calculateFromGrid($grid-base, 4))}; // 32px
  --b-icon-2xl: #{toRem(calculateFromGrid($grid-base, 5))}; // 40px
  --b-icon-3xl: #{toRem(calculateFromGrid($grid-base, 6))}; // 48px
  --b-icon-4xl: #{toRem(calculateFromGrid($grid-base, 8))}; // 64px
  --b-icon-5xl: #{toRem(calculateFromGrid($grid-base, 12))}; // 96px
}
