$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mediaQueries';

.hideOnMobile {
  @media #{$onlyMobile} {
    display: none !important;
  }
}

.hideOnTablet {
  @media #{$onlyTablet} {
    display: none !important;
  }
}

.hideOnLaptop {
  @media #{$onlyLaptop} {
    display: none !important;
  }
}

.hideOnDesktop {
  @media #{$onlyDesktop} {
    display: none !important;
  }
}
