$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';

.overlay {
  position: fixed;
  z-index: var(--b-z-index-5);
  top: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: var(--b-color-bg-overlay-transparent);
  transition: background-color 0.2s ease;
}

:global(.ReactModal__Overlay--after-open).overlay {
  background-color: var(--b-color-bg-overlay);
}

:global(.ReactModal__Overlay--before-close).overlay {
  background-color: var(--b-color-bg-overlay-transparent);
  transition-duration: 0.6s;
}

.modalContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  transform: translateY(100%);
  transform: translateX(100%);

  width: 100%;

  background-color: var(--b-color-bg-white);
  transition: transform 0.3s ease;
  outline: none;

  :global(.ReactModal__Overlay--after-open) & {
    transform: translateX(0);
  }
  :global(.ReactModal__Overlay--before-close) & {
    transform: translateX(100%);
  }

  @media #{$fromLaptop} {
    width: toRem(756); // anchura definida en el diseño
  }

  [dir='rtl'] & {
    right: unset;
    left: 0;

    transform: translateX(-100%);
  }

  [dir='rtl'] :global(.ReactModal__Overlay--after-open) & {
    transform: translateX(0);
  }
  [dir='rtl'] :global(.ReactModal__Overlay--before-close) & {
    transform: translateX(-100%);
  }
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: toRem(1) solid var(--b-color-divider);
  padding: var(--b-space-s) var(--b-space-s) var(--b-space-s) var(--b-space-m);

  [dir='rtl'] & {
    padding: var(--b-space-s) var(--b-space-m) var(--b-space-s) var(--b-space-s);
  }
}

.exitButton {
  @include touch-security-zone-always;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  cursor: pointer;
}

.modalContent {
  flex-grow: 1;
  overflow: hidden;

  -webkit-overflow-scrolling: touch;
}

.modalContentScrollable {
  flex-grow: 1;

  overflow-x: hidden;

  -webkit-overflow-scrolling: touch;
}
