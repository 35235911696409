$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';

.container {
  @include fullWidthContainerLateralPadding;

  padding-top: var(--b-space-xl);
  padding-bottom: var(--b-space-xl);

  background-color: var(--b-color-bg-white);

  @media #{$fromLaptop} {
    box-shadow: var(--b-shadow-hard);
    border-radius: #{toRem(16)};
    padding: var(--b-space-xl);
  }

  &.isMobileDark {
    @media #{$onlyMobileAndTablet} {
      background-color: var(--b-color-bg-light);
    }
  }
}
