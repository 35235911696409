$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';

.container {
  position: relative;
  z-index: var(--b-z-index-4);

  transition: top 0.2s ease-in-out;

  @media #{$fromLaptop} {
    position: sticky;
    top: 0;
  }
}

.header {
  border-bottom: toRem(2) solid transparent;
  padding-top: var(--b-space-s);
  padding-bottom: var(--b-space-s);

  background-color: var(--b-color-icon-dark);
  border-image: var(--b-color-fill-ocean-100-gradient);
  border-image-slice: 1;

  @media #{$fromLaptop} {
    border-bottom: toRem(8) solid transparent;
    padding-top: var(--b-space-l);
    padding-bottom: var(--b-space-l);
  }
}

.contentWrapper {
  display: flex;
  gap: var(--b-space-xs);
  align-items: center;
  overflow: hidden;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  @include contentContainer;
}
