$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';.container {
  position: relative;

  display: inline-block;

  width: var(--size);
  height: var(--size);
}

.loader {
  --size-to-border-ratio: 8;
  --border-size: calc(var(--size) / var(--size-to-border-ratio));

  position: absolute;

  display: block;

  border: var(--border-size) solid var(--color);
  border-radius: 50%;
  border-color: var(--color) transparent transparent transparent;
  box-sizing: border-box;
  width: var(--size);
  height: var(--size);

  animation: spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

  &:nth-child(1) {
    animation-delay: -0.45s;
  }

  &:nth-child(2) {
    animation-delay: -0.3s;
  }

  &:nth-child(3) {
    animation-delay: -0.15s;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
