$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/functions';

.navigation {
  display: none;

  @media #{$fromLaptop} {
    --padding: var(--b-space-xs);
    position: absolute;
    z-index: var(--b-z-index-3);
    top: calc(50% - calc(var(--padding) * 2));

    display: flex;
    opacity: 0.95;

    box-shadow: var(--b-shadow-soft);
    border: toRem(1) solid var(--b-color-interactive-primary-enabled);
    border-radius: 50%;
    padding: var(--padding);

    background-color: var(--b-color-icon-light);

    &:hover {
      cursor: pointer;
    }
  }
}

.leftNavigation {
  left: calc((var(--b-space-3xl) + var(--padding)) * -1);
}


.rightNavigation {
  right: calc((var(--b-space-3xl) + var(--padding)) * -1);
}

.closeButton {
  position: relative;
  z-index: var(--b-z-index-3);
  top: calc(50% - calc(var(--padding) * 2));

  display: flex;
  opacity: 0.95;

  margin-left: var(--b-space-xxs);
  box-shadow: var(--b-shadow-soft);
  border-radius: 50%;
  padding: var(--padding);

  background-color: var(--b-color-icon-light);

  --padding: var(--b-space-xs);

  &:hover {
    cursor: pointer;
  }

  [dir='rtl'] & {
    margin-left: unset;
    margin-right: var(--b-space-xxs);
  }
}

.closeButtonLabel {
  display: none;

  @media #{$fromLaptop} {
    display: block;
  }
}

.closeButtonWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin-top: var(--b-space-m);

  cursor: pointer;

  [dir='rtl'] & {
    right: unset;
    left: 0;

    margin-left: var(--b-space-m);
    margin-right: unset;
  }

  @media #{$fromLaptop} {
    margin-top: var(--b-space-2xl);
    margin-right: unset;
  }

  @media #{$onlyMobileAndTablet} {
    @media (orientation: landscape) {
      position: absolute;
      right: 0;

      margin-right: var(--b-space-m);

      [dir='rtl'] & {
        right: unset;
        left: 0;
      }
    }
  }
}
