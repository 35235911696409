$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';
@import 'src/ui/styles/tools/mixins/containers';
@import 'src/ui/styles/tools/mixins/gridLayout';
@import 'src/ui/styles/tools/mixins/common';

@function maxContentHeight($total, $bottomSpace) {
  @return calc($total - var(--modal-header-height) - $bottomSpace);
}

.modalContent {
  --modal-content-bottom-space: var(--b-space-3xl);
  --modal-content-bottom-space-for-max-height: 0px;
  --modal-content-lateral-space: var(--b-space-3xl);
  --modal-content-scrollbar-margin: var(--b-space-xl);
  --modal-content-scrollbar-padding: var(--b-space-m);
  --modal-total-height: 100vh - #{toRem(64)};
  @supports (height: 100dvh) {
    --modal-total-height: 100dvh - #{toRem(64)};
  }
  margin-left: 0;
  margin-right: var(--modal-content-scrollbar-margin);
  max-height: #{maxContentHeight(
      var(--modal-total-height),
      var(--modal-content-bottom-space-for-max-height)
    )};
  padding-bottom: var(--modal-content-bottom-space);
  padding-left: var(--b-space-l);
  padding-right: var(--modal-content-scrollbar-padding);

  overflow-y: auto;
  overflow-x: hidden;

  &_transactional {
    --modal-content-bottom-space-for-max-height: var(
      --modal-content-bottom-space
    );
  }

  &_withButtonsFooter {
    padding-bottom: unset;
  }

  @include personalizedModalWebkitScrollBar;
  [dir='rtl'] & {
    margin-left: var(var(--modal-content-scrollbar-margin));
    margin-right: 0;
    padding-left: var(--modal-content-scrollbar-padding);
    padding-right: var(--b-space-l);
  }

  @media #{$onlyMobile} {
    margin-left: 0;
    margin-right: var(--b-space-m);
    [dir='rtl'] & {
      margin-left: var(--b-space-m);
      margin-right: 0;
    }
  }

  @media #{$fromTablet} {
    margin-left: 0;
    margin-right: var(--modal-content-scrollbar-margin);
    padding-left: var(--modal-content-lateral-space);
    padding-right: var(--modal-content-scrollbar-padding);
    [dir='rtl'] & {
      margin-left: var(--modal-content-scrollbar-margin);
      margin-right: 0;
      padding-left: var(--modal-content-scrollbar-padding);
      padding-right: var(--modal-content-lateral-space);
    }
  }

  @media #{$onlyTablet} {
    --modal-content-bottom-space: #{toRem(56)};

    &_s {
      max-height: #{maxContentHeight(
          75vh,
          var(--modal-content-bottom-space-for-max-height)
        )};
    }

    &_m {
      max-height: #{maxContentHeight(
          75vh,
          var(--modal-content-bottom-space-for-max-height)
        )};
    }

    &_l {
      max-height: #{maxContentHeight(
          75vh,
          var(--modal-content-bottom-space-for-max-height)
        )};
    }

    &_xl {
      max-height: #{maxContentHeight(
          90vh,
          var(--modal-content-bottom-space-for-max-height)
        )};
    }
  }

  @media #{$onlyMobile} {
    &_isMobileFullscreen {
      max-height: 100%;
    }
  }
}
