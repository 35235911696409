$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/functions';

.overlay {
  position: fixed;
  z-index: var(--b-z-index-5);
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;

  width: 100%;
  height: 100%;

  background-color: var(--b-color-bg-light);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  max-width: #{toRem(1090)};
  height: #{toRem(64)};
  padding: var(--b-space-m);
}

.closeButton {
  display: flex;
  align-items: center;

  cursor: pointer;
}

.divider {
  border-bottom: toRem(1) solid var(--b-color-fill-neutral-02);
  width: 100%;
}

.container {
  display: grid;
  gap: var(--b-space-xs);
  grid-auto-flow: dense;
  grid-template-columns: repeat(4, 1fr);

  width: 100%;
  max-width: #{toRem(1090)};
  padding: var(--b-space-m);

  @media #{$onlyMobileAndTablet} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.large {
  grid-column: span 2;
  grid-row: span 2;
}

.lastLarge {
  grid-column: 3 / span 2;
  grid-row: span 2;
}

.small {
  grid-column: span 1;
  grid-row: span 1;
}

.wide {
  grid-column: 1 / span 2;
  grid-row: span 1;
}

.img {
  border-radius: #{toRem(16)};

  object-fit: cover;
  cursor: pointer;
}
