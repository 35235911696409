$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mixins/common';

.container {
  display: flex;
  flex-direction: column;

  height: 100%;
}

.tabListContainer {
  display: flex;
  gap: var(--b-space-l);
  flex-shrink: 0;

  border-bottom: toRem(2) solid var(--b-color-fill-ocean-10);
  padding: 0 var(--b-space-m) 0 var(--b-space-m);
}

.tab {
  padding: var(--b-space-xs);

  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.tabActive {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -#{toRem(2)};
    left: 0;

    border-bottom: toRem(2) solid var(--b-color-interactive-secondary-enabled);
    border-radius: toRem(2);
    width: 100%;
  }

  & > * {
    @include font-m-500;
  }
}

.tabPanelContainer {
  flex-grow: 1;

  overflow-y: auto;
}

.selectedTabPanel {
  height: 100%;
}
