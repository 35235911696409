$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';

.phoneFieldContainer {
  display: flex;
  gap: var(--b-space-m);
  align-items: flex-start;
  flex-shrink: 0;
  [dir='rtl'] & {
    --react-international-phone-dropdown-left: auto;
  }
}

.countrySelector {
  display: flex;
  gap: var(--b-space-xs);
  align-items: center;
  align-self: stretch;

  border: toRem(1) solid var(--b-color-field-enabled);
  border-radius: var(--b-border-radius-8);
  height: toRem(48);
  padding: var(--b-space-xs) var(--b-space-s);

  background: var(--b-color-bg-white);
}

.countrySelectorError {
  border-color: var(--b-color-support-error);
}

.countrySelectorButton {
  border: none;
}

.countrySelectorButton > div {
  gap: var(--b-space-xxs);
}

.phoneFieldContainer > {
  .phoneInput {
    @include font-m-300;
    @include textField();
    box-shadow: none;
    border: toRem(1) solid var(--b-color-field-enabled);
    border-radius: var(--b-border-radius-8);
    width: toRem(274);
    height: toRem(40);
    padding: var(--b-space-xs) var(--b-space-s);

    color: var(--b-color-text-mid);
    background: var(--b-color-bg-white);

    &:hover,
    &:focus-visible,
    &:focus,
    &:hover:not(:focus):not(:active):not(:disabled) {
      box-shadow: none;
    }

    [dir='rtl'] & {
      direction: rtl;
    }
  }

  .phoneInputError {
    border-color: var(--b-color-support-error);
  }
}

.countrySelectorDropdown {
  display: flex;
  align-items: center;
  align-self: stretch;

  margin-top: var(--b-space-m);
  border: toRem(1) solid var(--b-color-text-dark);
  border-radius: var(--b-border-radius-10);
  width: fit-content;
  padding: var(--b-space-m) var(--b-space-xs);
}

.countrySelectorDropdown:focus-visible {
  outline: none;
}

.countrySelectorDropdownItem {
  display: flex;
  gap: var(--b-space-xs);
  align-items: center;

  width: toRem(352);
  height: toRem(48);
  padding: var(--b-space-s) var(--b-space-s) var(--b-space-s) toRem(17);
}

.countrySelectorDropdownItem > img {
  width: toRem(30);
}

.countrySelectorDropdownArrow {
  border: none;
  width: toRem(16);
  height: toRem(16);

  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIKICAgICB2aWV3Qm94PSIwIDAgMTYgMTYiIGZpbGw9Im5vbmUiPgogIDxwYXRoIGQ9Ik0zLjY2NjY5IDZMOC4xNjY2OSAxMC41TDEyLjY2NjcgNiIgc3Ryb2tlPSIjMzg0MDQ0IgogICAgICAgIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K');
}
