$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';

.checkbox {
  position: relative;

  flex-shrink: 0;
  align-self: self-start;

  margin: 0;
  border: toRem(1) solid var(--b-color-interactive-primary-enabled);
  border-radius: var(--b-border-radius-2);
  width: toRem(18);
  height: toRem(18);

  appearance: none;

  cursor: pointer;

  &:hover {
    background-color: var(--b-color-interactive-primary-hover);
  }

  &:checked {
    border-color: var(--b-color-interactive-primary-enabled);

    background-color: var(--b-color-interactive-primary-active);

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;

      transform: translate(-50%, -50%);

      width: toRem(14);
      height: toRem(14);

      background-image:  url('./Check.svg');

      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &:disabled {
    border-color: var(--b-color-interactive-primary-disabled);

    cursor: not-allowed;
  }
}

.error {
  border-color: var(--b-color-support-error-dark);
}

.label {
  display: flex;
  gap: var(--b-space-xs);
  align-items: center;

  &:hover {
    cursor: pointer;
  }
}
