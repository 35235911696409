$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/functions';

.navigation {
  display: none;

  outline: toRem(1) var(--b-color-interactive-primary-enabled);

  @media #{$fromLaptop} {
    --padding: var(--b-space-xs);

    position: absolute;
    z-index: var(--b-z-index-3);
    top: calc(50% - calc(var(--padding) * 2));

    display: flex;
    opacity: 0.95;

    box-shadow: var(--b-shadow-soft);
    border-radius: 50%;
    padding: var(--padding);

    background-color: var(--b-color-icon-light);

    &:hover {
      cursor: pointer;
    }
  }
}

.leftNavigation {
  left: var(--b-space-l);
}


.rightNavigation {
  right: var(--b-space-l);
}
